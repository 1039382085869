var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.grid_key
        ? _c("FormController", {
            staticClass: "form",
            attrs: { submit: _vm.submit, "data-form-type": "other" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c("p", [
                        _vm._v(
                          " You will need an API key to connect wit GRID data. You can access your API key in the GRIDs "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://dashboard.grid.gg/my-organisation",
                              target: "_blank",
                              rel: "noopener noreferrer"
                            }
                          },
                          [_vm._v('"My Organization"')]
                        ),
                        _vm._v(
                          " section, viewing any of the accounts and navigating to the API Keys tab. "
                        )
                      ]),
                      _c("p", [
                        _vm._v(" Go to "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://dashboard.grid.gg/my-organisation",
                              target: "_blank",
                              rel: "noopener noreferrer"
                            }
                          },
                          [_vm._v('"My Organization"')]
                        )
                      ]),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "flex-fill",
                          attrs: {
                            label: "GRID API key",
                            "label-for": "grid_key"
                          }
                        },
                        [
                          _c("b-form-input", {
                            class: {
                              "is-valid": _vm.$v.form.grid_key.$model !== "",
                              "is-invalid": _vm.$v.form.grid_key.$error
                            },
                            attrs: { id: "grid_key", type: "text" },
                            model: {
                              value: _vm.$v.form.grid_key.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.form.grid_key,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.form.grid_key.$model"
                            }
                          }),
                          _vm.$v.form.grid_key.$error &&
                          !_vm.$v.form.grid_key.required
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v("Field is required")
                              ])
                            : _vm._e(),
                          _vm.$v.form.grid_key.$error &&
                          !_vm.$v.form.grid_key.checkKey
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(" API key is not valid. ")
                              ])
                            : _vm._e(),
                          _vm.$v.form.grid_key.$pending
                            ? _c(
                                "div",
                                { staticClass: "text-secondary mt-3" },
                                [_c("p", [_vm._v("Checking API key...")])]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            type: "submit",
                            variant: "warning",
                            disabled: _vm.$v.form.$invalid
                          }
                        },
                        [_vm._v(" Submit ")]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2572522197
            )
          })
        : _vm._e(),
      _vm.grid_key
        ? _c("FormController", {
            staticClass: "form",
            attrs: { submit: _vm.clear, "data-form-type": "other" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c("p", [
                        _vm._v(" Your GRID API key is "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.grid_key.substr(0, 3)) +
                              "..." +
                              _vm._s(_vm.grid_key.substr(-3))
                          )
                        ])
                      ]),
                      _c(
                        "b-button",
                        { attrs: { type: "submit", variant: "danger" } },
                        [_vm._v(" Clear ")]
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2346597883
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }